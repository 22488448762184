import React, { useEffect, useState } from "react";
import Loader from "../../../Layouts/Loader";
import { getUserDetails } from "../../../Services/Users";

const UserProfile = ({ userid }) => {
  const [userDetails, setUserDetails] = useState();
  const phone = userDetails?.phone_no && {
    first: userDetails?.phone_no.split("").slice(0, 3).join(""),
    second: userDetails?.phone_no.split("").slice(3, 6).join(""),
    last: userDetails?.phone_no.split("").slice(6).join(""),
  };
  console.log("number", phone);

  useEffect(() => {
    getUserDetails(userid)
      .then((res) => {
        console.log(res);
        setUserDetails(res.data.data.responseData[0]);
      })
      .catch((err) => err.response.data);
  }, []);
  return (
    <>
      <div className="page_header">
        <h2>User Details</h2>
      </div>
      {userDetails ? (
        <>
          <div className="card mt-4">
            <div className="row">
              <div className="col-lg-3 p-0">
                <div className="card-body text-center mt-4 mb-4 m-4">
                  <img
                    src={
                      userDetails.image_url
                        ? userDetails.image_url
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                    }
                    alt="avatar"
                    className=" img-fluid"
                    style={{
                      width: "250px",
                      height: "250px",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-9 ps-0">
                <div className="card-body mt-4 mb-4 profile-dis ps-0">
                  <h2>{userDetails.full_name}</h2>
                  <p>{userDetails.about_yourself}</p>
                  <div className="row profile_info mt-4">
                    <div className="col-5 ">
                      <label>Email</label>
                      <p>{userDetails.email}</p>
                    </div>
                    <div className="col-5 ">
                      <label>Phone</label>
                      <p>
                        {userDetails.phone_no
                          ? `(${phone.first}) ${phone.second}-${phone.last}`
                          : "N/A"}
                      </p>
                    </div>
                    <div className="col-10 ">
                      <label>Payment Link</label>
                      <br />
                      <p>
                        {userDetails.payment_link
                          ? userDetails.payment_link
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UserProfile;
