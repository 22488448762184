import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateQuestion } from "../../../Services/PreQuestion";
import { useDispatch } from "react-redux";
import { updatePreQuestions } from "../../../Redux/Action/Question";

const UpdateQuestion = ({ lgShow, closeLgShow, question }) => {
  const notifyError = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);
  const dispatch = useDispatch();
  const redirect = useNavigate();
  // const location = useLocation();
  const questionData = {
    ...question,
    choices: [
      {
        ...question.choices[0],
        action: "update",
      },
      {
        ...question.choices[1],
        action: "update",
      },
    ],
  };
  const initialValues = questionData;
  const validationSchema = Yup.object({
    question: Yup.string()
      .required("Please enter your question")
      .max(200, "Maximum 200 characters long"),
    choices: Yup.array().of(
      Yup.object().shape({
        choice: Yup.string()
          .required("Please enter your choice")
          .max(40, "Maximum 40 characters long")
          .test("regex", "Minimum 1 characters long", (val) => {
            let regExp = new RegExp("[a-zA-Z_0-9]+");
            return regExp.test(val);
          }),
      })
    ),
  });
  const onSubmit = (values, { setSubmitting }) => {
    updateQuestion(values)
      .then((res) => {
        console.log(res);
        dispatch(updatePreQuestions(values));
        notifySuccess(res.data.message);
        closeLgShow();
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.response.data.message);
      });
  };
  return (
    <>
      <Modal
        // size="md"
        show={lgShow}
        onHide={closeLgShow}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        backdrop="static"
        className="Queston-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Update Question
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-body">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                console.log("Formik props", formik);
                return (
                  <>
                    <Form className="md-float-material form-material">
                      <div className="p-4">
                        <div class="form-group">
                          <label for="inputQuestion" className="label">
                            Question
                          </label>
                          <Field
                            name="question"
                            type="textarea"
                            className=" form-control question-form-control"
                            // placeholder="question"
                            as="textarea"
                            id="inputQuestion"
                          />
                          <div className="error-message">
                            <ErrorMessage
                              name="question"
                              className="form-text text-muted"
                            />
                          </div>
                        </div>
                        <div>
                          <FieldArray
                            name="choices"
                            render={(arrayHelpers) => (
                              <>
                                {formik.values.choices.map((option, index) => (
                                  <div class="form-group ">
                                    <label
                                      className="label"
                                      for="inputChoice"
                                    >{`Options ${index + 1}`}</label>
                                    <Field
                                      type="text"
                                      // placeholder={`option ${index + 1}`}
                                      name={`choices.${index}.choice`}
                                      as="textarea"
                                      className=" form-control"
                                      id="inputChoice"
                                    />
                                    <div className="error-message">
                                      <ErrorMessage
                                        name={`choices.${index}.choice`}
                                        className="form-text text-muted"
                                      />
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <Modal.Footer>
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={closeLgShow}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn que-btn"
                          disabled={formik.isSubmitting}
                        >
                          Save
                        </button>
                      </Modal.Footer>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateQuestion;
